import AdvText from "@components/data/text";
import AdvButton from "@components/inputs/button-new";
import AdvCheckbox from "@components/inputs/checkbox";
import AdvLanguageDropdown from "@components/inputs/dropdown-new/language-dropdown/language-dropdown";
import AdvTextInput from "@components/inputs/text-input-new";
import AdvPage from "@components/layout/page";
import AdvStack, { TAdvStackStyles } from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item/stack-item";
import { AdvCredits } from "@components/navigation/credits/info";
import { AdvNavTitle } from "@components/navigation/nav/nav-title";
import AdvIcon from "@components/other/icon/icon";
import { TPageComponentProps } from "@components/page-component";
import { LAN } from "@data/language/strings";
import { getRecoilResourceStorage, TResourceStorageItem } from "@data/resource-storage";
import {
    afterLoginTargetAtom,
    defaultStartPage,
    rememberLoginAtom,
    sessionAddInfosAtom,
    TLoginResultExtraTypes,
    TLoginResultTypes,
} from "@data/session";
import { themeAuto } from "@data/theme/atoms";
import TwoFactorAuthCreateModal from "@feature/login/2FA/create-modal";
import TwoFactorAuthInputModal from "@feature/login/2FA/input-modal";
import PasswordResetModal from "@feature/login/password-reset";
import { DefaultThemeLogo } from "@feature/settings/theme-types";
import { Image, ImageFit, IStackProps, IStackStyles, ITheme, Link } from "@fluentui/react";
import { toAdvText } from "@hooks/language/useTranslation";
import useIsMounted from "@hooks/misc/useIsMounted";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilState from "@hooks/recoil-overload/useAdvRecoilState";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvResetRecoilState from "@hooks/recoil-overload/useAdvResetRecoilState";
import { EUsesOTP, IServerLoginResponse, useAdvLogin } from "@hooks/useAdvLogin";
import { WarningIcon } from "@themes/icons";
import deepCopy from "@utils/deep-copy";
import { advcatch, advlog } from "@utils/logging";
import {
    buildPageParserParam,
    buildQueryAsStr,
    gAdvDynPageName,
    gAdvPageAccessKey,
    gDefaultIgnoreParamKeys,
    getQueryValue,
    parsePageName,
    TPageParserParam,
} from "@utils/page-parser";
import { NextPage } from "next";
import { KeyboardEvent, useRef, useState } from "react";

// #region(collapsed) Styles
const pageStackStyle = (
    resourceBackground: TResourceStorageItem | undefined,
): Partial<IStackStyles> => {
    return {
        root: {
            backgroundImage:
                resourceBackground != undefined && resourceBackground.Value != ""
                    ? "url(data:" +
                      resourceBackground.Name.DataType +
                      ";base64," +
                      resourceBackground.Value +
                      ")"
                    : undefined,
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            opacity: 0.95,
            margin: 0,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
        },
    };
};
const formStackStyle = function (stackProps: IStackProps, theme: ITheme) {
    return {
        root: {
            backgroundColor: theme.palette.white + "70",
            backdropFilter: "blur(7px)",
            padding: "8px",
            borderRadius: "6px",
            boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 90%)",
            width: "400px",
            maxWidth: "90vw",
        },
    };
};
const infoStackStyle = function (stackProps: IStackProps, theme: ITheme) {
    return {
        root: {
            backgroundColor: theme.palette.white + "70",
            backdropFilter: "blur(7px)",
            padding: "8px 0px",
            borderRadius: "6px",
            boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 90%)",
            width: "400px",
            maxWidth: "90vw",
        } as TAdvStackStyles,
    };
};
// #endregion

const PageLogin: NextPage<TPageComponentProps> = (pageProps: TPageComponentProps) => {
    const { login } = useAdvLogin();

    const { isMounted } = useIsMounted();

    const [afterLoginTarget] = useAdvRecoilState(afterLoginTargetAtom);
    const resetAfterLoginTarget = useAdvResetRecoilState(afterLoginTargetAtom);
    const [usernameOrEmail, setUsernameOrEmail] = useState("");
    const [password, setPassword] = useState("");
    const [shouldRemember, setRemember] = useAdvRecoilState(rememberLoginAtom);

    const shouldActivateOTP = useRef<EUsesOTP>(EUsesOTP.unknown);
    const otp = useRef<string>("");

    const [token, setToken] = useState<string>("");

    const [resetEmail, setResetEmail] = useState<string>("");
    const [otpSecret, setOtpSecret] = useState<string>("");
    const [shouldForce2FA, setForce2FA] = useState<boolean>(false);

    const [isLoading, setLoading] = useState(false);
    const [errorUsername, setErrorUsername] = useState<string>("");
    const [errorPassword, setErrorPassword] = useState<string>("");

    const [isResetActive, setResetActive] = useState(false);
    const [is2FAInputActive, set2FAInputActive] = useState(false);
    const [is2FACreationActive, set2FACreationActive] = useState(false);

    const router = useAdvRouter();
    const session = useAdvRecoilValue(sessionAddInfosAtom);

    const onUsernameChange = useAdvCallback(function (newValue?: string | undefined) {
        setUsernameOrEmail(newValue ?? "");
    }, []);

    const onPasswordChange = useAdvCallback(function (newValue?: string | undefined) {
        setPassword(newValue ?? "");
    }, []);

    const onRememberChange = useAdvCallback(
        function (checked?: boolean | undefined) {
            setRemember(checked ?? false);
        },
        [setRemember],
    );

    const getNextPage = useAdvCallback((): {
        pageName: string;
        pageQueryStr: string;
        pageActionToken: string;
    } => {
        if (afterLoginTarget.pathname != "") {
            const queryList: TPageParserParam[] = buildPageParserParam(
                afterLoginTarget.query,
                gDefaultIgnoreParamKeys,
            );
            const pageToken = getQueryValue(afterLoginTarget.query, gAdvPageAccessKey);
            return {
                pageName: parsePageName(afterLoginTarget),
                pageQueryStr: buildQueryAsStr(queryList),
                pageActionToken: pageToken,
            };
        } else {
            return {
                pageName: session.Startpage != null ? session.Startpage : defaultStartPage,
                pageQueryStr: "",
                pageActionToken: "",
            };
        }
    }, [afterLoginTarget, session.Startpage]);

    const validateInput = useAdvCallback(
        async function (): Promise<{
            result: TLoginResultTypes;
            resultExtra: TLoginResultExtraTypes;
            data: IServerLoginResponse | undefined;
        }> {
            let errUser = "";
            let errPass = "";

            let loginRes = TLoginResultTypes.lrtUserPwNOK;
            let loginExtraRes = TLoginResultExtraTypes.lrtEmpty;

            if (usernameOrEmail.length == 0) errUser = "Required";
            // if (password.length == 0) errPass = "Required";

            let loginData: IServerLoginResponse | undefined = undefined;
            if (errUser == "" && errPass == "") {
                const nextPage = getNextPage();

                setLoading(true);
                setErrorUsername("");
                setErrorPassword("");
                try {
                    const loginResult = await login(
                        usernameOrEmail,
                        password,
                        shouldRemember,
                        nextPage.pageName,
                        nextPage.pageQueryStr,
                        nextPage.pageActionToken,
                        otp.current,
                        shouldActivateOTP.current,
                        otpSecret,
                        router.origQuery["wsid"] as number | undefined,
                    );

                    loginRes = loginResult.result;
                    loginExtraRes = loginResult.resultExtra;
                    loginData = loginResult.data;

                    switch (loginRes) {
                        case TLoginResultTypes.lrtUserPwNOK: {
                            // Username/Passwort ist falsch
                            errUser = LAN.LOGIN_ERR_USERNAME.text;
                            errPass = LAN.LOGIN_ERR_USERNAME.text;
                            break;
                        }
                        case TLoginResultTypes.lrtNeedOTP: {
                            // OTP-Token benötigt (nicht angegeben)
                            errPass = LAN.LOGIN_ERR_NEED_OTP.text;
                            break;
                        }
                        case TLoginResultTypes.lrtOtpNOK: {
                            // OTP-Token angegeben aber inkorrekt
                            errPass = LAN.LOGIN_ERR_WRONG_OTP.text;
                            otp.current = ""; // Kommt von TwoFactorAuthInputModal
                            break;
                        }
                        case TLoginResultTypes.lrtLoginOK: {
                            // Login erfolgreich
                            setOtpSecret("");
                            otp.current = "";
                            shouldActivateOTP.current = EUsesOTP.unknown;
                            break;
                        }
                        case TLoginResultTypes.lrtInitOTP: {
                            // OTP (2FA) muss initial konfiguriert werden
                            errPass = LAN.LOGIN_ERR_CONF_OTP.text;
                            setForce2FA((oldValue: boolean) => {
                                if (loginResult.force2FA === undefined) return oldValue;
                                if (loginResult.force2FA != oldValue) return loginResult.force2FA;
                                return loginResult.force2FA;
                            });
                            setOtpSecret((oldValue: string) => {
                                if (loginResult.otpSecret === undefined) return oldValue;
                                if (loginResult.otpSecret != oldValue) return loginResult.otpSecret;
                                return loginResult.otpSecret;
                            });
                            break;
                        }
                        case TLoginResultTypes.lrtNLiveUser: {
                            // Benutzer ist kein LiveUser (Passwort ist aber korrekt)
                            setOtpSecret("");
                            otp.current = "";
                            errPass = LAN.LOGIN_ERR_NO_PERM.text;
                            break;
                        }
                        case TLoginResultTypes.lrtInactive: {
                            errPass = LAN.LOGIN_ERR_INACTIVE.text;
                            break;
                        }
                    }
                } finally {
                    setLoading(false);
                }
            }

            setErrorUsername(errUser);
            setErrorPassword(errPass);

            return { result: loginRes, resultExtra: loginExtraRes, data: loginData };
        },
        [usernameOrEmail, getNextPage, login, password, shouldRemember, otpSecret],
    );

    const onSubmit = useAdvCallback(
        function () {
            validateInput()
                .then((valid) => {
                    if (valid.result == TLoginResultTypes.lrtLoginOK && isMounted()) {
                        if (
                            afterLoginTarget.pathname != "" &&
                            valid.resultExtra != TLoginResultExtraTypes.lrtInvalidRedirect
                        ) {
                            const queryList: TPageParserParam[] = buildPageParserParam(
                                afterLoginTarget.query,
                                [],
                            );
                            const target =
                                afterLoginTarget.pathname +
                                "/" +
                                router.addInstanceToQuery(buildQueryAsStr(queryList));
                            resetAfterLoginTarget();
                            router.push(target).catch(advcatch);
                        } else {
                            router
                                .push(
                                    "/dynamic/" +
                                        router.addInstanceToQuery(
                                            gAdvDynPageName + "=" + valid.data?.Startpage,
                                        ),
                                )
                                .catch(advcatch);
                        }
                    } else if (valid.result == TLoginResultTypes.lrtNeedOTP && isMounted()) {
                        set2FAInputActive(true);
                    } else if (valid.result == TLoginResultTypes.lrtInitOTP && isMounted()) {
                        set2FACreationActive(true);
                    }
                })
                .catch((r) => {
                    advlog("Could not verify login data: ", r);
                });
        },
        [validateInput, isMounted, afterLoginTarget, resetAfterLoginTarget, router],
    );

    useAdvEffect(() => {
        if (router.isReady) {
            if (router.query.token !== undefined) {
                setToken(router.query.token as string);

                if (router.query.typ === undefined) {
                    setResetActive(true);
                }
            }
            if (router.query.email !== undefined) {
                setResetEmail(router.query.email as string);
                setResetActive(true);
            }
        }
    }, [router.isReady, router.query]);

    const onResetPassword = useAdvCallback(() => {
        setResetActive(true);
    }, [setResetActive]);

    const [showCapslockWarning, setShowCapslockWarning] = useState<boolean>(false);
    const onKeyDown = useAdvCallback(
        function (ev: KeyboardEvent) {
            if (ev.key.toLowerCase() == "enter") onSubmit();
            else if (ev.getModifierState("CapsLock")) {
                setShowCapslockWarning(true);
            } else {
                setShowCapslockWarning(false);
            }
        },
        [onSubmit],
    );

    const handle2FACreationClosed = useAdvCallback(
        (activate2FA?: boolean, token?: string) => {
            set2FACreationActive(false);

            if (
                activate2FA !== undefined &&
                (activate2FA == false || (activate2FA && token !== undefined && token != ""))
            ) {
                shouldActivateOTP.current = activate2FA ? EUsesOTP.yes : EUsesOTP.no;
                otp.current = token ?? "";

                onSubmit();
            }
        },
        [onSubmit],
    );

    const handle2FAInputClosed = useAdvCallback(
        (token?: string) => {
            set2FAInputActive(false);

            if (token !== undefined && token != "") {
                otp.current = token;
                onSubmit();
            }
        },
        [onSubmit],
    );

    const theme = useAdvRecoilValue(themeAuto);

    const resBackground = useAdvRecoilValue(
        getRecoilResourceStorage(true).ResourceValues(
            theme?.Value.logo.backgroundLogin ?? deepCopy(DefaultThemeLogo.backgroundLogin),
        ),
    );

    return (
        <AdvPage title={LAN.LOGIN_HEADER.text}>
            <PasswordResetModal
                resetToken={token}
                resetEmail={resetEmail}
                isOpen={isResetActive}
                styles={{
                    root: {
                        main: {
                            width: "45vw",
                            minWidth: "350px",
                            maxWidth: "550px",
                        },
                    },
                }}
                onClosed={() => setResetActive(false)}
            />
            <TwoFactorAuthInputModal
                isOpen={is2FAInputActive}
                onClosed={handle2FAInputClosed}
                styles={{
                    root: {
                        main: {
                            width: "40vw",
                            minWidth: "300px",
                            maxWidth: "500px",
                        },
                    },
                }}
            />
            <TwoFactorAuthCreateModal
                isOpen={is2FACreationActive}
                onClosed={handle2FACreationClosed}
                username={usernameOrEmail}
                secret={otpSecret}
                shouldForce2FA={shouldForce2FA}
                styles={{
                    root: {
                        main: {
                            width: "40vw",
                            minWidth: "300px",
                            maxWidth: "500px",
                        },
                    },
                }}
            />
            <AdvStack
                horizontalAlign="center"
                verticalAlign="center"
                styles={pageStackStyle(resBackground.IsLoaded() ? resBackground.Get() : undefined)}
                verticalFill
            >
                <AdvStackItem>
                    <AdvStack tokens={{ childrenGap: 12 }} styles={formStackStyle}>
                        <AdvNavTitle logoFull={true} />
                        <AdvTextInput
                            key="usernameOrEmail"
                            translatableTextLabel={toAdvText(LAN.USERNAME_OR_EMAIL)}
                            value={usernameOrEmail}
                            onValueChanged={onUsernameChange}
                            onKeyDown={onKeyDown}
                            required={true}
                            disabled={isLoading}
                            translatableTextErrorMessage={toAdvText(errorUsername)} //FIXME ignoreTranslate false?
                            autoFocus
                            tabIndex={1}
                        />
                        <AdvStackItem>
                            <AdvTextInput
                                key="password"
                                translatableTextLabel={toAdvText(LAN.PASSWORD)}
                                value={password}
                                onValueChanged={onPasswordChange}
                                onKeyDown={onKeyDown}
                                required={true}
                                disabled={isLoading}
                                translatableTextErrorMessage={toAdvText(errorPassword)}
                                type="password"
                                canRevealPassword
                                onRenderLabel={(props, defaultRender) => {
                                    return (
                                        <AdvStack horizontal horizontalAlign="space-between">
                                            <AdvStackItem shrink={0} align="center">
                                                {defaultRender != undefined && defaultRender(props)}
                                            </AdvStackItem>
                                            <AdvStackItem shrink={0} align="center">
                                                <Link
                                                    href={""}
                                                    onClick={onResetPassword}
                                                    disabled={isLoading}
                                                    underline
                                                >
                                                    {LAN.FORGOT_PASSWORD.text}
                                                </Link>
                                            </AdvStackItem>
                                        </AdvStack>
                                    );
                                }}
                                tabIndex={2}
                            />
                            {showCapslockWarning == false ? null : (
                                <AdvText
                                    styles={{
                                        root: {
                                            color: theme?.Value?.main?.palette?.red ?? "red",
                                            fontWeight: "600",
                                        },
                                    }}
                                >
                                    <AdvIcon
                                        iconName={WarningIcon.iconName}
                                        style={{ verticalAlign: "bottom" }}
                                    />
                                    Capslock ist aktiviert!
                                </AdvText>
                            )}
                        </AdvStackItem>
                        <AdvStack horizontal horizontalAlign="space-between">
                            <AdvStackItem align="center" grow={1}>
                                <AdvCheckbox
                                    key="remember"
                                    label={LAN.STAY_LOGGED_IN.text}
                                    value={shouldRemember}
                                    onValueChanged={onRememberChange}
                                    required={true}
                                    disabled={isLoading}
                                    inputProps={{ tabIndex: 3 }}
                                />
                            </AdvStackItem>
                            <AdvStackItem grow={0} shrink>
                                <AdvLanguageDropdown
                                    translatableTextLabel={toAdvText("")}
                                    tabIndex={4}
                                    pageProps={pageProps}
                                />
                            </AdvStackItem>
                        </AdvStack>
                        <AdvButton
                            primary={true}
                            onClick={onSubmit}
                            disabled={isLoading}
                            tabIndex={5}
                            flexGrow
                        >
                            {LAN.LOGIN.text}
                        </AdvButton>
                    </AdvStack>
                </AdvStackItem>
                <AdvStackItem>
                    <AdvStack
                        horizontalAlign="space-between"
                        verticalAlign="end"
                        horizontal
                        wrap
                        styles={infoStackStyle}
                    >
                        <AdvStackItem grow={1} styles={{ root: { textAlign: "center" } }}>
                            <AdvText
                                key="poweredBy"
                                id="poweredBy"
                                ignoreTranslation
                                styles={{
                                    root: { display: "inline-block", verticalAlign: "middle" },
                                }}
                            >
                                Powered by&nbsp;
                            </AdvText>
                            <Link href="https://www.advantex.de" target="_blank">
                                <Image
                                    src="/images/footer.png"
                                    alt="AdvanTex"
                                    height={19}
                                    imageFit={ImageFit.contain}
                                    styles={{
                                        root: {
                                            display: "inline-block",
                                            verticalAlign: "text-bottom",
                                        },
                                    }}
                                />
                            </Link>
                        </AdvStackItem>
                        <AdvStackItem
                            grow={1}
                            styles={{ root: { height: 19, textAlign: "center" } }}
                        >
                            <AdvCredits></AdvCredits>
                        </AdvStackItem>
                    </AdvStack>
                </AdvStackItem>
            </AdvStack>
        </AdvPage>
    );
};

export default PageLogin;
